<template>
  <van-grid :column-num="2">
    <van-grid-item :to="{ name: 'Withdrawal'}">
      <p>
        <small>{{ balance }} 元</small>
      </p>
      <p>
        <small>可提现</small>
      </p>
    </van-grid-item>
    <van-grid-item>
      <van-button v-if="bindWechat" size="small" type="danger" @click="onBindWeixin">解绑微信</van-button>
      <van-button v-else size="small" type="success" @click="onBindWeixin">绑定微信</van-button>
    </van-grid-item>
  </van-grid>
  <van-cell-group title="选择店铺" inset>
    <template v-for="item in items" :key="item">
      <van-cell :title="item.name" is-link @click="onClick(item.storeId)" />
    </template>
  </van-cell-group>
  <div style="margin:15px">
    <van-button block :to="{ name: 'StoreCreate'}">新建店铺</van-button>
  </div>
  <div style="margin:15px;">
    <van-button block :to="{ name: 'UpdatePassword'}">修改密码</van-button>
  </div>
  <div style="margin:15px;">
    <van-button block @click="onLogout">退出登录</van-button>
  </div>
  <!-- <div style="margin:15px;">
    <van-button block @click="test">测试创建商户</van-button>
  </div>
  <div style="margin:15px;">
    <van-button block @click="searchTest">查询申请结果</van-button>
  </div> -->
  <rights />
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import Rights from '@/components/Rights.vue'
import { Toast } from 'vant'

export default {
  components: {
    Rights
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      show: false,
      items: [],
      sellerId: Cookies.get('sellerId'),
      userId: Cookies.get('userId'),
      bindWechat: true,
      seller: {},
      balance: 0
    })
    const searchTest = (applymentId) => {
      applymentId = 2000002284993974
      post('/getqueryapplystatus', { applymentId: applymentId }).then(res => {
        console.log(res)
      })
    }
    const test = () => {
      const data = {
        outRequestNo: 'APPLYMENT_123457',
        organizationType: '2401',
        needAccountInfo: false,
        salesSceneInfo: {
          storeName: '小微测试',
          storeUrl: 'https://test-shop.feddon.com?storeId=482697'
        },
        merchantShortname: '小微测试',
        contactInfo: {
          contactType: '65',
          contactName: '高峰',
          contactIdCardNumber: '510131197803061010',
          mobilePhone: '18981912728',
          contactEmail: '1490208693@qq.com'
        },
        idCardInfo: {
          idCardCopy: 'f7RjIb75ZHHeuUPY9nXtSZ_6G8X3wXtUoFXLCknYYfKwrUhWUBUlu0zROSTrxqgOzz8GZ-1jRoCzSRAl1Qh-lza8GwyI3zs0o5-gZ3j8ZSc',
          idCardNational: 'f7RjIb75ZHHeuUPY9nXtSSMrU6V1JpTFkBND5XUx6bkQjzNtp3miIoNNselLO5qBqxWgASwp4kM40hh57eMMgZPi_KwtNhk1qvb7ompkJzQ',
          idCardName: '高峰',
          idCardNumber: '510131197803061010',
          idCardValidTime: '2032-03-26'
        }
      }
      post('/createapply', data).then(res => {
        if (res.code === 0) {
          Toast('申请已提交，请等待审核')
        } else {
          Toast(res.msg)
        }
      })
    }
    const onBindWeixin = () => {
      if (typeof Cookies.get('wechatOpenid') === 'undefined') {
        useRouter.push({
          name: 'WechatOauth',
          query: {
            redirect: useRouter.currentRoute.value.fullPath
          }
        })
      } else {
        post('/user.bindWechat', {
          userId: state.userId,
          bind: !state.bindWechat,
          wechatOpenid: Cookies.get('wechatOpenid')
        }).then(res => {
          if (res.code === 0) {
            state.bindWechat = !state.bindWechat
          }
        })
      }
    }
    const onClick = (storeId) => {
      Cookies.set('storeId', storeId, 1)
      useRouter.push({
        name: 'StoreIndex',
        query: {}
      })
    }
    const onLogout = () => {
      for (const item of Object.keys(Cookies.get())) {
        Cookies.remove(item)
      }
      useRouter.push({
        name: 'Login',
        query: {
          redirect: '/',
          role: 'SELLER'
        }
      })
    }
    const init = () => {
      post('/store.list', {
        sellerId: state.sellerId,
        pageSize: 50
      }).then(res => {
        state.items = res.data.content
      })
      post('/seller.get', {
        sellerId: state.sellerId
      }).then(res => {
        state.seller = res.data
        state.bindWechat = state.seller.user.wechatOpenid !== ''
        state.balance = state.seller.user.balance
      })
      console.log(useRouter.currentRoute.value.fullPath)
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onClick,
      onBindWeixin,
      onLogout,
      test,
      searchTest
    }
  }
}
</script>
